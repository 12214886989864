import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import BottomButton from "@/components/BottomButton";
import api from "@/api";
import useRazorpayPayment from "@/hooks/useRazorpayPayment";
import { orderActions } from "@/store/order";
import handleError from "@/utils/handleError";

import formatOrderPayload from "../utils/formatOrderPayload";

const PaymentButton = () => {
  const dispatch = useDispatch();
  const order = useSelector((state) => state.orderSlice.order);
  const [createdOrder, setCreatedOrder] = useState({});
  const { invokePayment } = useRazorpayPayment();

  const onSuccess = async ({ orderId, pgPaymentId, pgSignature }) => {
    try {
      const response = await api.orders.completePayment({
        orderId,
        pgPaymentId,
        pgSignature,
      });

      if (
        response.data.order.payment.finalStatus === "completed" &&
        response.data.order.finalStatus === "active"
      ) {
        dispatch(orderActions.resetOrder());
        toast.success("Order created successfully");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      handleError(error);
    }
  };

  const paymentHandler = async () => {
    try {
      if (!createdOrder._id) {
        const orderPayload = formatOrderPayload(order);
        const orderResponse = await api.orders.create(orderPayload);
        setCreatedOrder(orderResponse.data.order);
        invokePayment({ order: orderResponse.data.order, onSuccess });
      } else {
        invokePayment({ order: createdOrder, onSuccess });
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <BottomButton isArrowHidden color="success" onClick={paymentHandler}>
      Pay now
    </BottomButton>
  );
};

export default PaymentButton;
