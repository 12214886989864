import { Card, CardContent, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import CurrentLocation from "@/components/CurrentLocation";
import LocationSearchInput from "@/components/LocationSearchInput";
import { kitchensActions } from "@/store/kitchens";

const SetupLocation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitAddress = ({ formattedAddress, latitude, longitude }) => {
    const address = {
      formattedAddress,
      latitude,
      longitude,
    };
    dispatch(kitchensActions.clearAddress());
    localStorage.setItem("address", JSON.stringify(address));
    navigate("/");
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography
          variant="subtitle2"
          textAlign="center"
          style={{ marginBottom: "20px" }}
        >
          SETUP YOUR LOCATION TO GET STARTED
        </Typography>
        <div style={{ marginBottom: "10px" }}>
          <LocationSearchInput />
        </div>
        <CurrentLocation submitAddress={submitAddress} />
      </CardContent>
    </Card>
  );
};

export default SetupLocation;
