import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import CurrentLocation from "@/components/CurrentLocation";
import api from "@/api";
import useDebounce from "@/hooks/useDebounce";
import { kitchensActions } from "@/store/kitchens";
import handleError from "@/utils/handleError";

import LocationSearch from "./components/LocationSearch";
import SuggestionList from "./components/SuggestionList";

const LocationSearchPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const debouncedSearchValue = useDebounce(searchValue.trim(), 300);

  useEffect(() => {
    const getPlacesAutocomplete = async () => {
      try {
        const {
          data: { suggestions },
        } = await api.maps.getPlacesAutocomplete({
          input: debouncedSearchValue,
        });
        if (suggestions) setSuggestions(suggestions);
      } catch (error) {
        handleError(error);
      }
    };

    if (debouncedSearchValue) {
      getPlacesAutocomplete();
    } else {
      setSuggestions([]);
    }
  }, [debouncedSearchValue]);

  const submitAddress = ({ formattedAddress, latitude, longitude }) => {
    const address = {
      formattedAddress,
      latitude,
      longitude,
    };
    dispatch(kitchensActions.clearAddress());
    localStorage.setItem("address", JSON.stringify(address));
    navigate("/");
  };

  const handleSuggestionSelect = async (suggestion) => {
    try {
      const {
        placePrediction: { placeId },
      } = suggestion;

      const {
        data: {
          formattedAddress,
          location: { latitude, longitude },
        },
      } = await api.maps.getPlaceDetails({ placeId });

      submitAddress({ formattedAddress, latitude, longitude });
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Container>
      <LocationSearch
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <CurrentLocation submitAddress={submitAddress} />
      {suggestions.length > 0 && (
        <SuggestionList
          suggestions={suggestions}
          onSuggestionSelect={handleSuggestionSelect}
        />
      )}
    </Container>
  );
};

export default LocationSearchPage;
