import React, { useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import { Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import BottomDrawer from "@/components/BottomDrawer";
import api from "@/api";
import handleError from "@/utils/handleError";

import RatingsList from "./RatingsList";

const Rating = ({ average, count }) => {
  const { kitchenId } = useParams();
  const [ratings, setRatings] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const fetchRatings = async () => {
    try {
      const {
        data: { ratings },
      } = await api.ratings.getAllKitchenRatings({
        kitchenId,
      });
      setRatings(ratings);
    } catch (error) {
      handleError(error);
    }
  };

  const handleRatingClick = async () => {
    setIsDrawerOpen(true);
    await fetchRatings();
  };

  return (
    <>
      <div
        style={{
          border: "1px solid #e9e9eb",
          borderRadius: "6px",
          textAlign: "center",
          padding: "8px",
          marginBottom: "8px",
          cursor: "pointer",
        }}
        onClick={handleRatingClick}
      >
        <Typography
          variant="subtitle2"
          style={{
            paddingBottom: "10px",
            borderBottom: "1px solid #e9e9eb",
            fontWeight: "700",
            marginBottom: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "gold",
          }}
        >
          <StarIcon
            style={{
              fontSize: "1.2rem",
              marginRight: "2px",
            }}
          />
          {average ? average.toFixed(1) : "0"}
        </Typography>
        <Typography
          variant="body2"
          style={{
            fontSize: "11px",
            color: "#8b8d97",
            fontFamily:
              "ProximaNovaCondensedRegular, arial, Helvetica Neue, sans-serif",
            fontWeight: "600",
            textAlign: "center",
            textDecoration: "underline",
            textUnderlineOffset: "3px",
          }}
        >
          {count ? count : "No"} ratings
        </Typography>
      </div>
      <BottomDrawer
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        title="Ratings"
      >
        <RatingsList ratings={ratings} />
      </BottomDrawer>
    </>
  );
};

export default Rating;
