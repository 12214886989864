import { lazy } from "react";

const CompletedDeliveriesPage = lazy(
  () => import("./pages/CompletedDeliveriesPage"),
);
const DeliveryPage = lazy(() => import("./pages/DeliveryPage"));

const deliveryRoutes = [
  {
    path: "deliveries/completed",
    component: <CompletedDeliveriesPage />,
    isProtected: true,
  },
  {
    path: "deliveries/:deliveryId",
    component: <DeliveryPage />,
    isProtected: true,
  },
];

export default deliveryRoutes;
