import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";

const LocationSearch = ({ searchValue, setSearchValue }) => {
  const navigate = useNavigate();
  const handleClear = () => {
    setSearchValue("");
  };

  return (
    <TextField
      style={{ margin: "20px 0px" }}
      fullWidth
      size="small"
      placeholder="Search Location"
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={() => navigate("/")} size="small" edge="start">
              <ArrowBackIosNewIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: searchValue && (
          <InputAdornment position="end">
            <IconButton onClick={handleClear} size="small" edge="end">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default LocationSearch;
