import addressRoutes from "@/modules/addresses/addressRoutes";
import authRoutes from "@/modules/auth/authRoutes";
import createOrderRoutes from "@/modules/create-order/createOrderRoutes";
import deliveryRoutes from "@/modules/deliveries/deliveryRoutes";
import homeRoute from "@/modules/home/homeRoute";
import locationSearchRoute from "@/modules/location-search/locationSearchRoute";
import orderRoutes from "@/modules/orders/orderRoutes";
import paymentRoute from "@/modules/payments/paymentRoute";
import profileRoute from "@/modules/profile/profileRoute";
import refundRoute from "@/modules/refunds/refundRoute";
import welcomeRoute from "@/modules/welcome/welcomeRoute";

const routes = [
  homeRoute,
  welcomeRoute,
  locationSearchRoute,
  ...authRoutes,
  ...createOrderRoutes,
  ...orderRoutes,
  ...deliveryRoutes,
  ...addressRoutes,
  paymentRoute,
  refundRoute,
  profileRoute,
];

export default routes;
