import { Container } from "@mui/material";
import { useDispatch } from "react-redux";

import BottomDrawer from "@/components/BottomDrawer";
import { kitchensActions } from "@/store/kitchens";

import CurrentLocation from "../CurrentLocation";
import LocationSearchInput from "../LocationSearchInput";

const LocationDrawer = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const submitAddress = ({ formattedAddress, latitude, longitude }) => {
    const address = {
      formattedAddress,
      latitude,
      longitude,
    };
    localStorage.setItem("address", JSON.stringify(address));
    dispatch(kitchensActions.setAddress(address));
    setIsOpen(false);
  };

  return (
    <BottomDrawer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title={"Change Location"}
    >
      <Container style={{ paddingTop: "30px" }}>
        <div style={{ marginBottom: "10px" }}>
          <LocationSearchInput />
        </div>
        <CurrentLocation submitAddress={submitAddress} />
      </Container>
    </BottomDrawer>
  );
};

export default LocationDrawer;
