import { useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { AppBar, Button, Container, Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@/contexts/AuthContext";

import styles from "./Header.module.css";
import LocationDrawer from "./LocationDrawer";
import Logo from "./Logo";
import UserAccountIcon from "./UserAccountIcon";

const Header = ({
  hideBackButton,
  showLocation,
  hideRightSection,
  hideLogo,
}) => {
  const authCtx = useAuth();
  const navigate = useNavigate();
  const address = JSON.parse(localStorage.getItem("address"));
  const [isLocationDrawerOpen, setIsLocationDrawerOpen] = useState(false);

  const openLocationDrawer = () => {
    setIsLocationDrawerOpen(true);
  };

  return (
    <>
      <AppBar position="sticky">
        <Container>
          <Toolbar className={styles.header}>
            <div
              className={styles.left}
              style={{ width: showLocation && "60%" }}
            >
              {!hideBackButton && (
                <ArrowBackIcon
                  onClick={() => navigate(-1)}
                  style={{ cursor: "pointer" }}
                />
              )}
              {showLocation && (
                <div className={styles.location} onClick={openLocationDrawer}>
                  <LocationOnIcon />
                  <Typography className={styles.locationText}>
                    {address?.formattedAddress}
                  </Typography>
                  <ArrowDropDownIcon />
                </div>
              )}
            </div>
            {!hideLogo && (
              <div className={styles.center}>
                <Logo />
              </div>
            )}
            <div className={styles.right}>
              {!hideRightSection &&
                (authCtx.isLoggedIn ? (
                  <UserAccountIcon />
                ) : (
                  <Button
                    style={{ fontSize: "12px" }}
                    size="small"
                    color="inherit"
                    variant="outlined"
                    onClick={() => navigate("/auth")}
                  >
                    Sign In
                  </Button>
                ))}
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      <LocationDrawer
        isOpen={isLocationDrawerOpen}
        setIsOpen={setIsLocationDrawerOpen}
      />
    </>
  );
};

export default Header;
