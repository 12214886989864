import { useEffect, useRef } from "react";
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "@/components/Header";
import NoListFoundText from "@/components/NoListFoundText";
import api from "@/api";
import { kitchensActions } from "@/store/kitchens";
import handleError from "@/utils/handleError";

import { KitchenCard, KitchenSearchAndFilters } from "./components";

const KitchensPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const localStorageAddressRef = useRef(
    JSON.parse(localStorage.getItem("address")),
  );
  const { address: globalStorageAddress, filteredKitchens } = useSelector(
    (state) => state.kitchensSlice,
  );

  useEffect(() => {
    const fetchKitchens = async () => {
      try {
        const {
          data: { kitchens },
        } = await api.kitchens.getAllKitchens({
          latitude: globalStorageAddress.latitude,
          longitude: globalStorageAddress.longitude,
        });

        dispatch(kitchensActions.setKitchens(kitchens));
      } catch (error) {
        handleError(error);
      }
    };

    if (globalStorageAddress.latitude && globalStorageAddress.longitude) {
      fetchKitchens();
    }
  }, [dispatch, navigate, globalStorageAddress]);

  useEffect(() => {
    if (
      !localStorageAddressRef.current?.latitude ||
      !localStorageAddressRef.current?.longitude
    ) {
      return navigate("/welcome", { replace: true });
    }

    dispatch(kitchensActions.setAddress(localStorageAddressRef.current));
  }, [dispatch, navigate]);

  return (
    <>
      <Header hideBackButton showLocation hideLogo />
      <Container>
        <KitchenSearchAndFilters />
        {filteredKitchens.map((kitchen) => (
          <KitchenCard key={kitchen._id} kitchen={kitchen} />
        ))}
        {filteredKitchens.length === 0 && (
          <NoListFoundText>No kitchens found</NoListFoundText>
        )}
      </Container>
    </>
  );
};

export default KitchensPage;
