import React from "react";
import StarIcon from "@mui/icons-material/Star";
import { List, ListItem, ListItemText, Typography } from "@mui/material";

import NoListFoundText from "@/components/NoListFoundText";

const RatingsList = ({ ratings }) => {
  return (
    <>
      <List>
        {ratings.map((rating, index) => (
          <ListItem key={index} divider>
            <ListItemText
              primary={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography variant="body1" lineHeight={1}>
                      {rating.user.name}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {new Date(rating.date).toDateString()}
                    </Typography>
                  </div>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <StarIcon style={{ color: "gold" }} />
                    <Typography variant="body1">
                      {rating.rating.toFixed(1)}
                    </Typography>
                  </div>
                </div>
              }
              secondary={
                <Typography variant="body2" style={{ marginTop: "5px" }}>
                  {rating.review}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
      {ratings.length === 0 && (
        <NoListFoundText>No ratings found</NoListFoundText>
      )}
    </>
  );
};

export default RatingsList;
