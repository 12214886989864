import axios from "axios";

let base = "/kitchens/";

const getAllKitchens = async ({ latitude, longitude }) =>
  (await axios.get(base, { params: { latitude, longitude } })).data;

const get = async ({ kitchenId }) =>
  (await axios.get(base + "active/" + kitchenId)).data;

const kitchens = {
  getAllKitchens,
  get,
};

export default kitchens;
