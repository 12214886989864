import React, { useState } from "react";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { Alert, Button, CircularProgress } from "@mui/material";

import api from "@/api";
import handleError from "@/utils/handleError";

const CurrentLocation = ({ submitAddress }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const reverseGeocodeLocation = async ({ latitude, longitude }) => {
    try {
      const {
        data: { results },
      } = await api.maps.reverseGeocode({ latitude, longitude });
      const { lat, lng } = results[0].geometry.location;
      const { formatted_address } = results[0];

      submitAddress({
        formattedAddress: formatted_address,
        latitude: lat,
        longitude: lng,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleGetCurrentLocation = () => {
    if (navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setErrorMessage("");
          setLoading(false);
          reverseGeocodeLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting location:", error);
          setLoading(false);
          setErrorMessage(
            "Unable to retrieve location. Please allow location access.",
          );
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        },
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setErrorMessage("Geolocation is not supported by this browser.");
    }
  };

  return (
    <>
      <Button
        size="small"
        fullWidth
        variant="outlined"
        startIcon={
          loading ? <CircularProgress size={16} /> : <MyLocationIcon />
        }
        onClick={handleGetCurrentLocation}
        disabled={loading}
      >
        Use Current Location
      </Button>
      {errorMessage && (
        <Alert severity="error" style={{ marginTop: "20px" }}>
          {errorMessage}
        </Alert>
      )}
    </>
  );
};

export default CurrentLocation;
