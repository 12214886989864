import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { orderActions } from "@/store/order";

const HomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(orderActions.resetOrder());
    const address = JSON.parse(localStorage.getItem("address"));
    const latitude = address?.latitude;
    const longitude = address?.longitude;
    if (latitude && longitude) {
      navigate("/kitchens", { replace: true });
    } else {
      localStorage.removeItem("address");
      navigate("/welcome", { replace: true });
    }
  }, [dispatch, navigate]);

  return null;
};

export default HomePage;
