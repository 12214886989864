import axios from "axios";

import getAll from "@/utils/getAll";

let base = "/deliveries/";

const get = async ({ orderId, date }) =>
  (await axios.get(base + "delivery", { params: { orderId, date } })).data;

const skip = async ({ orderId, date }) =>
  (await axios.post(base + "skip", { orderId, date })).data;

const undoSkip = async ({ orderId, date }) =>
  (await axios.post(base + "undoSkip", { orderId, date })).data;

const getAllDeliveries = getAll(base);

const getById = async ({ deliveryId }) =>
  (await axios.get(base + deliveryId)).data;

const deliveries = {
  get,
  skip,
  undoSkip,
  getAllDeliveries,
  getById,
};

export default deliveries;
